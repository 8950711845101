import React from 'react'

// ReactorUi
import { Box, Flex } from 'reactor-ui'
import RichTextView from 'reactor-ui/components/RichTextView'
import * as RV from 'reactor-vera/templates/v1'
import Tenant from 'reactor-vera/components/Tenant'

import { RegisterPageWithId } from './PrRegister'
import PrUserProfile from './PrUserProfile'

import PrQr from '../components/PrQr'


const PrPublicSite = ({

}) => {
  const params = RV.useParams()
  return (
    <Tenant id={params.rcTenantId} channel='ReactorPublic'>
      <RV.Routes>
        <RV.Route path='/*' element={<PrPublicSiteContent />} />
        <RV.Route path="qr/:qrKey/*" element={<PrQr />} key='public' />,
      </RV.Routes>
    </Tenant>
  )
}


const PrPublicSiteContent = props => {
  const status = RV.useReactorResource('rcApp.state.status')
  const result = RV.useReactorQuery({
    'prPublicContent': {
      fields: [
        'content', 'properties'
      ],
    }
  })
  const params = RV.useParams()
  return (
    <RV.QueryLoading result={status}>
      <RV.QueryLoading result={result}>
        {({ prPublicContent }) => {
          return (
            <Box sx={{
              width: ['95%', null, null, '50%'],
              mx: 'auto',
              my: 8
            }}>
              <RV.Layout.Row>
                <RV.Link to='./'>
                  <Flex sx={{
                    alignItems: 'center'
                  }}>
                    <Box as='img' src={prPublicContent.content.logo_data?.url} sx={{
                      objectFit: 'cover',
                      maxHeight: '70px',
                      display: 'block',
                      mr: 6
                    }} />
                    <RV.Text.Header>
                      {prPublicContent.content.name}
                    </RV.Text.Header>
                  </Flex>
                </RV.Link>

                <Box>
                  {status.data?.rcUser ? (
                    <>
                      <RV.Link sx={{
                        color: 'brand.500',
                        textDecoration: 'underline',
                        mx: 3
                      }} to='./profile'>Profil</RV.Link>
                      <Logout />
                    </>
                  ) : (
                    <>
                      <RV.Link sx={{
                        color: 'brand.500',
                        textDecoration: 'underline',
                      }} to='./login'>Üyelik Girişi</RV.Link>
                    </>
                  )}
                </Box>
              </RV.Layout.Row>

              <RV.Routes>
                <RV.Route path='/' element={(
                  <>
                    {prPublicContent.content.description && (
                      <RV.Card my={2}>
                        <RichTextView value={prPublicContent.content.description} />
                      </RV.Card>
                    )}

                    <Properties properties={prPublicContent.properties} />
                  </>
                )} />

                <RV.Route path=':rnxPropertyId/*' element={<PrPublicProperty />} />
                <RV.Route path='login' element={<PrLogin status={status} />} />
                <RV.Route path='forgot' element={<PrForgot />} />
                <RV.Route path='profile/*' element={<PrUserProfile />} />
                <RV.Route path='payment/success/:cmrPaymentId' element={<PaymentSuccess />} />
                <RV.Route path='qr/:qrKey/*' element={<PrQr />} />
                <RV.Route path='invitation/:invitationKey/*' element={<MembershipInvitation />} />
                <RV.Route path={`${params.rcTenantId}/invitation/:invitationKey`} element={<RedirectInvitation/>}/>
              </RV.Routes>
            </Box>
          )
        }}
      </RV.QueryLoading>
    </RV.QueryLoading>
  )
}

const RedirectInvitation = ({

}) => {
  const navigate = RV.useNavigate()
  const params = RV.useParams()
  React.useEffect(() => {
    const to = `../invitation/${params.invitationKey}`
    navigate(to, { replace: true })
  }, [])
  return null
}

const MembershipInvitation = ({

}) => {
  const params = RV.useParams()
  const result = RV.useReactorQuery({
    'publicMembershipInvitation': {
      key: params.invitationKey
    }
  })
  return (
    <RV.QueryLoading result={result}>
      {({ publicMembershipInvitation }) => {
        console.log(publicMembershipInvitation)

        if (publicMembershipInvitation.status === 'ALREADY_ACCEPTED') {
          return (
            <RV.Alert status='warning'>
              Bu davetiye kullanılmış.
            </RV.Alert>
          )
        } else if (publicMembershipInvitation.status === 'CANCELLED') {
          return (
            <RV.Alert status='warning'>
              Bu davetiye iptal edilmiş.
            </RV.Alert>
          )
        } else if (publicMembershipInvitation.status === 'INVALID_USER') {
          return (
            <RV.Alert status='warning'>
              Bu davetiye size ait değil, davetiyeye ait kullanıcı ile giriş yapın.
            </RV.Alert>
          )
        } else if (publicMembershipInvitation.status === 'NEED_LOGIN') {
          return (
            <RV.Alert status='warning'>
              Bu davetiye tanımlı bir kullanıcıya ait, davetiyeye ait kullanıcı ile giriş yapın.
            </RV.Alert>
          )
        }

        return (
          <RegisterPageWithId id={publicMembershipInvitation.rnxMembershipTypeId} invitationCode={params.invitationKey} />
        )
      }}
    </RV.QueryLoading>
  )
}

const PaymentSuccess = ({

}) => {
  const params = RV.useParams()
  const dispatchAction = RV.useReactorAction('PrBase.CheckPaymentResult')
  const [status, setStatus] = React.useState(null)
  React.useEffect(() => {
    dispatchAction({ id: params.cmrPaymentId }, {
      success: data => {
        setStatus(data)
      }
    })
  }, [])

  let content
  if (!status) content = (
    'Kontrol Ediliyor'
  )
  else {
    if (status.result === 'SUCCESSFUL') {
      content = 'Ödemeniz başarıyla alındı'
    } else if (status.result === 'WAITING_PAYMENT') {
      content = 'Ödeme sonucu bekleniyor'
    } else if (status.result === 'EXPIRED') {
      content = 'Geçersiz ödeme'
    }
  }

  return (
    <Box>
      <RV.Alert status='info' mt={4}>
        {content}
      </RV.Alert>

      {status?.requireReservation && (
        <RV.Alert status='info' mt={4}>
          Randevu almadığınız taktirde giriş yapamazsınız. Lütfen sistem üzerinden gideceğiniz seans için randevu alınız.
        </RV.Alert>
      )}
    </Box>
  )
}

import { Form, RfFormError, RfFormField, useReactorActionForm, RfFormRow } from 'reactor-form/components/RfForm'

const PrLogin = ({
  status
}) => {
  const navigate = RV.useNavigate()
  const params = RV.useParams()
  const initialValues = {
    rcTenantId: params.rcTenantId,
    multiAccount: false
  }
  const [formProps, isSubmitting] = useReactorActionForm(
    'PrBase.LoginRcUser',
    {
      initialValues,
      onSuccess: () => window.location.reload()
    }
  )

  React.useEffect(() => {
    if (status.data?.rcUser) {
      navigate(`../profile`)
    }
  }, [status.data?.rcUser])

  return (
    <Box sx={{
      mx: 'auto',
      maxWidth: ['100%', null, null, '50%']
    }}>
      <Form {...formProps}>
        {/* {reactor.rConfig.logoPath && <Box sx={{ textAlign: 'center', mb: 3, mt: 3 }}><Box as='img' src={reactor.rConfig.logoPath} sx={{ width: '80%', mx: 'auto' }} /></Box>} */}
        {/* {reactor.rConfig.isMultiTenant && !rcTenantCode && <RfFormField name="rcTenantCode" label='Hesap' tabIndex={1} required />} */}
        <RfFormField name="email" label={'TC Kimlik No'} tabIndex={1} required autoFocus />
        <RfFormField name="phone" label={'Telefon'} tabIndex={1} required />
        <RfFormField name="password" label='Şifre' kind='password' tabIndex={2} required />
        {/* <RfFormField name='rememberMe' label='Beni hatırla' kind='checkbox' tabIndex={3} /> */}
        {/* <RfFormError error={formProps.error} message='Hatalı Bilgi.' /> */}
        {formProps.error && (
          <RV.Alert>
            Hatalı Bilgi
          </RV.Alert>
        )}
        {/* <Button label='GİRİŞ' fullWidth tabIndex={4} submit working={isSubmitting} /> */}
        <RV.Button colorScheme={'brand'} tabIndex={4} submit isLoading={isSubmitting} isFullWidth>
          GİRİŞ
        </RV.Button>
        <Flex sx={{
          flexDirection: 'row-reverse',
          mt: 3
        }}>
          <RV.Link to='../forgot' tabIndex={5} sx={{ textDecoration: 'underline' }}>
            Şifremi Unuttum
          </RV.Link>
        </Flex>
      </Form>
    </Box>
  )
}

const PrForgot = ({
  status
}) => {
  const navigate = RV.useNavigate()
  const params = RV.useParams()
  const [state, setState] = React.useState({ mode: null, data: null })

  if (state.mode === 'DONE') {
    return (
      <>
        <RV.Alert status='info'>
          Şifreniz sıfırlandı.
        </RV.Alert>
        <RV.Link to='../login' tabIndex={5} sx={{ textDecoration: 'underline' }}>
          Giriş
        </RV.Link>
      </>

    )
  }

  if (state.mode === 'CHECK') {
    return (
      <Box sx={{
        mx: 'auto',
        maxWidth: ['100%', null, null, '50%']
      }}>
        <RV.Text.Header>
          Şifre Sıfırlama
        </RV.Text.Header>

        <RV.RfActionForm
          actionKey='PrBase.ResetPasswordWithToken'
          key='tokenform'
          displayAs='FORM'
          fields={[
            {
              name: 'code',
              label: 'Şifre Sıfırlama Kodu',
              kind: 'text',
              description: 'Telefononuza gelen şifre sıfırlama kodu',
              required: true
            },

            {
              name: 'password',
              label: 'Yeni Şifre',
              kind: 'password',
              required: true
            },
          ]}
          initialValues={{
            id: state.data.id
          }}
          buttonText='Kontrol Et'
          onSuccess={(data) => {
            setState({ mode: 'DONE' })
          }}
        />

        <Flex sx={{
          flexDirection: 'row-reverse',
          mt: 3
        }}>
          <RV.Link to='../login' tabIndex={5} sx={{ textDecoration: 'underline' }}>
            Giriş
          </RV.Link>
        </Flex>
      </Box>
    )
  }

  return (
    <Box sx={{
      mx: 'auto',
      maxWidth: ['100%', null, null, '50%']
    }}>
      <RV.Text.Header>
        Şifre Sıfırlama
      </RV.Text.Header>

      <RV.RfActionForm
        actionKey='PrBase.ResetPassword'
        displayAs='FORM'
        fields={[
          {
            name: 'key',
            label: 'TC Kimlik No',
            kind: 'text',
            required: true
          },
        ]}
        initialValues={{}}
        buttonText='Kontrol Et'
        onSuccess={(data) => {
          setState({
            mode: 'CHECK',
            data: data
          })
        }}
      />

      <Flex sx={{
        flexDirection: 'row-reverse',
        mt: 3
      }}>
        <RV.Link to='../login' tabIndex={5} sx={{ textDecoration: 'underline' }}>
          Giriş
        </RV.Link>
      </Flex>
    </Box>
  )
}

const Properties = ({
  properties
}) => {
  return (
    <>
      <RV.Text.Header>
        Tesisler
      </RV.Text.Header>
      <Flex sx={{
        flexWrap: 'wrap',
        mx: -4,
        justifyContent: 'space-evenly',
        my: 4
      }}>
        {properties?.map((property, dx) => {
          return (
            <RV.Link to={`./${property.id}`} key={dx} sx={{
              flex: '1 1 0',
              maxWidth: ['90%', null, null, '45%'],
              minWidth: ['90%', null, null, '45%'],
              border: '1px solid #FFF',
              borderColor: 'brand.500',
              borderRadius: 2,
              position: 'relative',
              mx: ['auto', null, null, 4],
              height: '150px',
              overflow: 'hidden',
              mb: 3
            }}>
              <Box as='img' src={property.primaryImage?.url} sx={{
                objectFit: 'cover',
                width: '100%',
                display: 'block',
              }} />
              <Flex sx={{
                position: 'absolute',
                bottom: 4,
                right: 4,
                bg: '#FFF',
                color: 'brand.500',
                px: 3,
                py: 2,
                fontWeight: 'bold'
              }}>
                <Box as='img' src={property.logo?.url} sx={{
                  objectFit: 'cover',
                  width: '20px',
                  display: 'block',
                  mr: 2
                }} />
                {property.name}
              </Flex>
            </RV.Link>
          )
        })}
      </Flex>
    </>
  )
}

const PrPublicProperty = ({

}) => {
  const params = RV.useParams()
  const result = RV.useReactorQuery({
    'prPublicContent': {
      fields: [
        'content', 'properties'
      ],
    },
    'prPublicProperty': {
      fields: [
        'membershipPrograms', 'commerceProperties', 'singlePasses', 'calendars', 'singleActivityPasses', 'creditMembershipPrograms', 'properties'
      ],
      id: params.rnxPropertyId
    }
  })

  return (
    <RV.QueryLoading result={result}>
      {({ prPublicContent, prPublicProperty }) => {
        const property = prPublicContent.properties.find(x => x.id == params.rnxPropertyId)
        if (!property) return null
        return (
          <Box sx={{
            // width: ['95%', null, null, '50%'],
            // mx: 'auto',
            // my: 8
          }}>
            <RV.Card my={2}>
              <RV.Link to='./'>
                <Flex sx={{
                  alignItems: 'center'
                }}>
                  <Box as='img' src={property.logo?.url} sx={{
                    objectFit: 'cover',
                    maxHeight: '70px',
                    display: 'block',
                    mr: 6
                  }} />
                  <RV.Text.Header>
                    {property.name}
                  </RV.Text.Header>
                </Flex>
              </RV.Link>
            </RV.Card>

            <RV.Routes>
              <RV.Route path='/' element={(
                <>
                  {property.primaryImage && (
                    <Box as='img' src={property.primaryImage?.url} sx={{
                      objectFit: 'cover',
                      width: '100%',
                      display: 'block',
                      my: 4
                    }} />
                  )}

                  {property.description && (
                    <RV.Card my={2}>
                      <RichTextView value={property.description} />
                    </RV.Card>
                  )}

                  {prPublicProperty.membershipPrograms?.length > 0 && (
                    <RV.Card my={3}>
                      <RV.Card.Header>
                        Abonelik Programları
                      </RV.Card.Header>

                      {prPublicProperty.membershipPrograms?.map((pr, dx) => {
                        return (
                          <RV.Link to={`./program/${pr.id}`} key={dx} sx={{
                            my: 3,
                            textDecoration: 'underline'
                          }}>
                            <RV.Icon icon='file' color='brand.500'>
                              {pr.name}
                            </RV.Icon>
                          </RV.Link>
                        )
                      })}
                    </RV.Card>
                  )}

                  {prPublicProperty.creditMembershipPrograms?.length > 0 && (
                    <RV.Card>
                      <RV.Card.Header>
                        Günübirlik Giriş Programlarımız
                      </RV.Card.Header>

                      {prPublicProperty.creditMembershipPrograms?.map((pr, dx) => {
                        if (pr.types.length == 0) return null

                        return (
                          <Box key={dx}>
                            {prPublicProperty.creditMembershipPrograms.length > 1 ? pr.programName : ''}

                            {pr.types.map((t, tdx) => {
                              return (
                                <RV.Link to={`./creditMembership/${t.id}`} key={tdx} sx={{
                                  my: 3,
                                  textDecoration: 'underline'
                                }}>
                                  <RV.Icon icon='file' color='brand.500'>
                                    {t.name}
                                  </RV.Icon>
                                </RV.Link>
                              )
                            })}
                          </Box>
                        )
                      })}
                    </RV.Card>
                  )}

                  {prPublicProperty.singlePasses?.length > 0 && (
                    <RV.Card mt={4}>
                      <RV.Card.Header>
                        Günübirlik Girişler
                      </RV.Card.Header>

                      {prPublicProperty.singlePasses?.map((sp, dx) => {
                        return (
                          <RV.Link to={`./sp/${sp.id}`} key={dx} sx={{
                            my: 3,
                            textDecoration: 'underline'
                          }}>
                            <RV.Icon icon='file' color='brand.500'>
                              {sp.name} (₺{sp.price})
                            </RV.Icon>
                          </RV.Link>
                        )
                      })}
                    </RV.Card>
                  )}

                  {prPublicProperty.singleActivityPasses?.length > 0 && (
                    <RV.Card mt={4}>
                      <RV.Card.Header>
                        Aktivite Girişler
                      </RV.Card.Header>

                      {prPublicProperty.singleActivityPasses?.map((sp, dx) => {
                        return (
                          <RV.Link to={`./spa/${sp.id}`} key={dx} sx={{
                            my: 3,
                            textDecoration: 'underline'
                          }}>
                            <RV.Icon icon='file' color='brand.500'>
                              {sp.name} (₺{sp.price})
                            </RV.Icon>
                          </RV.Link>
                        )
                      })}
                    </RV.Card>
                  )}

                  {prPublicProperty.commerceProperties?.length > 0 && (
                    <RV.Card mt={4}>
                      <RV.Card.Header>
                        Tesis Hizmet Birimleri
                      </RV.Card.Header>

                      {prPublicProperty.commerceProperties?.map((pr, dx) => {
                        return (
                          <RV.Link to={`./crp/${pr.id}`} key={dx} sx={{
                            my: 3,
                            textDecoration: 'underline'
                          }}>
                            <RV.Icon icon='bank' color='brand.500'>
                              {pr.name}
                            </RV.Icon>
                          </RV.Link>
                        )
                      })}
                    </RV.Card>
                  )}

                  {prPublicProperty.properties?.calendarImage?.url && (
                    <RV.Card mt={4}>
                    <RV.Card.Header>
                      Takvim
                    </RV.Card.Header>

                    <Box as='img' src={prPublicProperty.properties?.calendarImage?.url} sx={{
                        objectFit: 'cover',
                        display: 'block',
                      }} />
                  </RV.Card>
                  )}

                  {(!prPublicProperty.properties?.calendarImage?.url) && prPublicProperty.calendars?.length > 0 && (
                    <RV.Card mt={4}>
                      <RV.Card.Header>
                        Takvimler
                      </RV.Card.Header>

                      {prPublicProperty.calendars?.map((cl, dx) => {
                        return (
                          <Box key={dx} my={4}>
                            <Box my={2}>
                              {cl.groupName}
                            </Box>
                            <ProgramDynamicCalendar calendar={cl.calendar} />
                          </Box>
                        )
                      })}
                    </RV.Card>
                  )}
                </>
              )} />

              <RV.Route path='program/:rnxMembershipProgramId/*' element={<PrPublicProgram property={property} />} />
              <RV.Route path='crp/:commerceRnxPropertyId/*' element={<PrPublicCommerceProperty />} />
              <RV.Route path='sp/:rnxResourceThingId/*' element={<PrBuySinglePass rnxPropertyId={params.rnxPropertyId} />} />
              <RV.Route path='spa/:rnxResourceThingId/*' element={<PrBuySingleActivityPass rnxPropertyId={params.rnxPropertyId} />} />

              <RV.Route path='creditMembership/:rnxMembershipTypeId/*' element={<PrCreditMembership property={property} />} />
              {/* <RV.Route path=':rnxPropertyId/register/:rnxMembershipProgramId' element={<PrPublicProgram />} /> */}
            </RV.Routes>
          </Box>
        )
      }}
    </RV.QueryLoading>
  )
}

const PrCreditMembership = ({

}) => {
  const params = RV.useParams()
  const result = RV.useReactorQuery({
    'prPublicCreditMembership': {
      // fields: [
      //   'name', 'products'
      // ],
      id: params.rnxMembershipTypeId
    }
  })
  return (
    <Box>
      <RV.Link to='./../../'>
        <RV.Icon name='chevron-left'>
          Geri Dön
        </RV.Icon>
      </RV.Link>

      <RV.QueryLoading result={result}>
        {({ prPublicCreditMembership }) => {
          return (
            <>
              <RV.Card>
                <RV.Card.Header>
                  {prPublicCreditMembership.name}
                </RV.Card.Header>

                <RV.Alert status='info' my={2}>
                  Günübirlik formunu doldurup, yetkililerin onayının ardından, ödemenizi yapıp tesisimize randevu ile giriş yapabilirsiniz.
                </RV.Alert>

                <RV.Card.Header>
                  Ücretler
                </RV.Card.Header>

                {prPublicCreditMembership.packages?.map((pkg, dx) => {
                  return (
                    <RV.Layout.Row key={dx}>
                      {pkg.name}
                      <Box>
                        ₺{pkg.price}
                      </Box>
                    </RV.Layout.Row>
                  )
                })}
              </RV.Card>

              <RegisterPageWithId id={params.rnxMembershipTypeId} />

            </>
          )
        }}
      </RV.QueryLoading>
    </Box>
  )
}

const PrBuySinglePass = ({
  rnxPropertyId,
}) => {
  const params = RV.useParams()
  const [status, setStatus] = React.useState(null)
  const result = RV.useReactorQuery({
    'prPublicSinglePass': {
      // fields: [
      //   'name', 'products'
      // ],
      id: params.rnxResourceThingId
    }
  })

  if (status) {
    return <ProductPayment state={{ id: status }} />
  }

  return (
    <RV.Routes>
      <RV.Route path='/' element={(
        <RV.QueryLoading result={result}>
          {({ prPublicSinglePass }) => {
            const capacityStatus = prPublicSinglePass.capacityStatus.status
            const isCapacityAvailable = capacityStatus <= 4

            let capacityText
            let capacityStatusKind
            if (capacityStatus === 1) {
              capacityText = 'Seyrek'
              capacityStatusKind = 'success'
            }
            else if (capacityStatus === 2) {
              capacityText = 'Az'
              capacityStatusKind = 'success'
            }
            else if (capacityStatus === 3) {
              capacityText = 'Normal'
              capacityStatusKind = 'success'
            }
            else if (capacityStatus === 4) {
              capacityText = 'Fazla'
              capacityStatusKind = 'warning'
            }
            else if (capacityStatus === 5) {
              capacityText = 'Çok Fazla'
              capacityStatusKind = 'error'
            }

            return (
              <Box>
                <RV.Card my={2}>
                  <RV.Card.Header>
                    {prPublicSinglePass.name} - (₺{prPublicSinglePass.price})
                  </RV.Card.Header>

                  <Box>
                    Başlangıç Saati: {prPublicSinglePass.properties.timeRange.start}
                  </Box>
                  <Box>
                    Bitiş Saati: {prPublicSinglePass.properties.timeRange.end}
                  </Box>

                  <Box textAlign='center' my={2}>
                    <RV.Alert status={capacityStatusKind} fontSize='l'>
                      Güncel Tesis Yoğunluğu: {capacityText}
                    </RV.Alert>
                  </Box>

                  {prPublicSinglePass.isAvailable && isCapacityAvailable ? (
                    <RV.RfActionForm
                      onSuccess={(data) => {
                        setStatus(data.id)
                      }}
                      actionKey='PrBase.PrepareAnonymousProductPayment'
                      displayAs='FORM'
                      fields={[
                        {
                          name: 'name',
                          label: 'İsim Soyisim',
                          kind: 'text',
                          required: true
                        },

                        {
                          name: 'govId',
                          label: 'TC Kimlik No',
                          kind: 'text',
                          required: true
                        },

                        {
                          name: 'phone',
                          label: 'Telefon',
                          kind: 'text',
                          required: true
                        },

                        {
                          name: 'gender',
                          label: 'Cinsiyet',
                          kind: 'checkboxRadio',
                          required: true,
                          options: {
                            options: [
                              {
                                'value': 'MALE',
                                'label': 'Erkek'
                              },

                              {
                                'value': 'FEMALE',
                                'label': 'Kadın'
                              }
                            ]
                          }
                        },

                        {
                          name: `custom.@${prPublicSinglePass.id}`,
                          label: ' ',
                          kind: 'group',
                          fields: [
                            ...prPublicSinglePass.customFields
                          ]
                        },

                        ...prPublicSinglePass.contractFields,
                      ]}
                      initialValues={{
                        id: rnxPropertyId,
                        products: [{
                          id: prPublicSinglePass.id,
                          count: 1
                        }]
                      }}
                      buttonText='ÖDEME AŞAMASINA GEÇ' />
                  ) : (
                    'Günlük rezervasyon şu an yapılamaz.'
                  )}
                </RV.Card>
              </Box>
            )
          }}
        </RV.QueryLoading>
      )} />
      {/* <RV.Route path='register/:rnxMembershipTypeId' element={<MembershipTypeRegister />} /> */}
    </RV.Routes>
  )
}

const PrBuySingleActivityPass = ({
  rnxPropertyId,
}) => {
  const params = RV.useParams()
  const [status, setStatus] = React.useState(null)
  const result = RV.useReactorQuery({
    'prPublicSingleActivityPass': {
      // fields: [
      //   'name', 'products'
      // ],
      id: params.rnxResourceThingId
    }
  })

  if (status) {
    return <ProductPayment state={{ id: status }} />
  }

  return (
    <RV.Routes>
      <RV.Route path='/' element={(
        <RV.QueryLoading result={result}>
          {({ prPublicSingleActivityPass }) => {
            console.log(prPublicSingleActivityPass.availableActivities)
            return (
              <Box>
                <RV.Card my={2}>
                  <RV.Card.Header>
                    {prPublicSingleActivityPass.name} - (₺{prPublicSingleActivityPass.price})
                  </RV.Card.Header>

                  {/* <Box>
                    Başlangıç Saati: {prPublicSingleActivityPass.properties.timeRange.start}
                  </Box>
                  <Box>
                    Bitiş Saati: {prPublicSingleActivityPass.properties.timeRange.end}
                  </Box> */}

                  {/* <Box textAlign='center' my={2}>
                    <RV.Alert status={capacityStatusKind} fontSize='l'>
                    Güncel Tesis Yoğunluğu: {capacityText}
                    </RV.Alert>
                  </Box> */}

                  {prPublicSingleActivityPass.isAvailable ? (
                    <RV.RfActionForm
                      onSuccess={(data) => {
                        setStatus(data.id)
                      }}
                      actionKey='PrBase.PrepareAnonymousProductPayment'
                      displayAs='FORM'
                      fields={[
                        {
                          name: 'activity',
                          label: 'Aktivite',
                          kind: 'select',
                          required: true,
                          options: {
                            options: prPublicSingleActivityPass.availableActivities?.map(a => ({
                              'label': `${a.name} | ${a.timeStart} - ${a.timeFinish}`,
                              'value': a.id
                            }))
                          }
                        },

                        {
                          name: 'name',
                          label: 'İsim Soyisim',
                          kind: 'text',
                          required: true
                        },

                        {
                          name: 'govId',
                          label: 'TC Kimlik No',
                          kind: 'text',
                          required: true
                        },

                        {
                          name: 'phone',
                          label: 'Telefon',
                          kind: 'text',
                          required: true
                        },

                        {
                          name: 'gender',
                          label: 'Cinsiyet',
                          kind: 'checkboxRadio',
                          required: true,
                          options: {
                            options: [
                              {
                                'value': 'MALE',
                                'label': 'Erkek'
                              },

                              {
                                'value': 'FEMALE',
                                'label': 'Kadın'
                              }
                            ]
                          }
                        },

                        {
                          name: `custom.@${prPublicSingleActivityPass.id}`,
                          label: ' ',
                          kind: 'group',
                          fields: [
                            ...prPublicSingleActivityPass.customFields
                          ]
                        },

                        ...prPublicSingleActivityPass.contractFields,
                      ]}
                      initialValues={{
                        id: rnxPropertyId,
                        products: [{
                          id: prPublicSingleActivityPass.id,
                          count: 1
                        }]
                      }}
                      buttonText='ÖDEME AŞAMASINA GEÇ' />
                  ) : (
                    'Günlük rezervasyon şu an yapılamaz.'
                  )}
                </RV.Card>
              </Box>
            )
          }}
        </RV.QueryLoading>
      )} />
      {/* <RV.Route path='register/:rnxMembershipTypeId' element={<MembershipTypeRegister />} /> */}
    </RV.Routes>
  )
}

const PrPublicCommerceProperty = ({

}) => {
  const params = RV.useParams()
  const result = RV.useReactorQuery({
    'prPublicCommerceProperty': {
      fields: [
        'name', 'products', 'contracts'
      ],
      id: params.commerceRnxPropertyId
    }
  })
  return (
    <RV.Routes>
      <RV.Route path='/' element={(
        <RV.QueryLoading result={result}>
          {({ prPublicCommerceProperty }) => {
            return (
              <Box>
                <RV.Card my={2}>
                  <RV.Card.Header>
                    {prPublicCommerceProperty.name}
                  </RV.Card.Header>

                  {/* <RV.Card.Header>
                    Ürünler
                  </RV.Card.Header> */}

                  <ProductSelection contracts={prPublicCommerceProperty.contracts} rnxPropertyId={prPublicCommerceProperty.id} products={prPublicCommerceProperty.products} />
                </RV.Card>
              </Box>
            )
          }}
        </RV.QueryLoading>
      )} />
      {/* <RV.Route path='register/:rnxMembershipTypeId' element={<MembershipTypeRegister />} /> */}
    </RV.Routes>
  )
}

import useReactorReducer from 'reactor/hooks/useReactorReducer'

const ProductSelection = ({
  rnxPropertyId,
  products,
  contracts
}) => {
  const [state, actions] = useReactorReducer({
    selectedProductIds: [],
    selectedProducts: {},
    total: 0,
    isLocked: false,
    mode: 'CART',
    id: null
  }, {
    addProduct: (draft, { id, name, price }) => {
      if (draft.isLocked) return
      if (!draft.selectedProducts[id]) {
        draft.selectedProducts[id] = {
          id,
          name,
          price,
          count: 1,
          subTotal: price
        }
        draft.selectedProductIds.push(id)
      } else {
        draft.selectedProducts[id].count += 1
        draft.selectedProducts[id].subTotal += price
      }
      draft.total += price
    },

    increaseCount: (draft, { id, count }) => {
      if (draft.isLocked) return
      if (!draft.selectedProducts[id]) {
        return
      }
      const priceChange = draft.selectedProducts[id].price * count
      draft.selectedProducts[id].count += count
      draft.selectedProducts[id].subTotal += priceChange
      draft.total += priceChange
    },

    decreaseCount: (draft, { id, count }) => {
      if (draft.isLocked) return
      if (!draft.selectedProducts[id]) {
        return
      }
      const priceChange = draft.selectedProducts[id].price * count

      if (draft.selectedProducts[id].count === 1) {
        delete draft.selectedProducts[id]
        draft.selectedProductIds = draft.selectedProductIds.filter(x => x !== id)
      } else {
        draft.selectedProducts[id].count -= count
        draft.selectedProducts[id].subTotal -= priceChange
      }

      draft.total -= priceChange
    },

    moveToCheckout: (draft,) => {
      draft.isLocked = true
      draft.mode = 'CHECKOUT'
    },

    moveToCart: (draft,) => {
      draft.isLocked = false
      draft.mode = 'CART'
    },

    moveToPayment: (draft, { id }) => {
      draft.isLocked = true
      draft.mode = 'PAYMENT'
      draft.id = id
    },
  })

  return (
    <Flex sx={{
      flexDirection: ['column', null, null, 'row']
    }}>
      <Box sx={{
        flex: '1 1 0'
      }}>
        {products?.map((product, dx) => {
          return (
            <Flex key={dx} sx={{
              py: 2
            }}>
              <Box sx={{
                flex: '0 0 150px',
                fontWeight: 'bold'
              }}>
                <RV.Icon name='asterisk' color='brand.500'>
                  {product.name}
                </RV.Icon>
              </Box>

              <Box sx={{
                flex: '0 0 50px'
              }}>
                ₺{product.price}
              </Box>

              <Box sx={{
                flex: '0 0 50px'
              }}>
                {!state.isLocked && <RV.Button onClick={() => actions.addProduct({ id: product.id, name: product.name, price: product.price })} colorScheme='brand' icon='plus' size='xs' />}
              </Box>
            </Flex>
          )
        })}
      </Box>

      <Box sx={{
        flex: '1 1 0'
      }}>
        <RV.Card.Header>
          Sepet
        </RV.Card.Header>

        {state.total === 0 && 'Satın almak istediğiniz ürünleri seçin.'}

        {state.selectedProductIds.map(id => {
          const p = state.selectedProducts[id]
          return (
            <Flex key={id}>
              <Box sx={{
                flex: '1 1 0'
              }}>
                {p.name}
              </Box>

              <Box sx={{
                fontWeight: 'bold'
              }}>
                ({p.count}) ₺{p.subTotal}
              </Box>

              <Flex sx={{
                fontWeight: 'bold',
                mx: -1,
                px: 1
              }}>
                {!state.isLocked && <RV.Button sx={{ mx: 1 }} onClick={() => actions.increaseCount({ id: p.id, count: 1 })} colorScheme='brand' icon='plus' size='xs' />}
                {!state.isLocked && <RV.Button sx={{ mx: 1 }} onClick={() => actions.decreaseCount({ id: p.id, count: 1 })} colorScheme='brand' icon='minus' size='xs' />}
              </Flex>
            </Flex>
          )
        })}

        <Box mt={3} fontWeight='bold'>
          TOPLAM: ₺{state.total}
        </Box>

        {state.mode === 'CART' && state.total !== 0 && (
          <RV.Button isLoading={state.isSubmitting} onClick={() => actions.moveToCheckout()} colorScheme='brand' isFullWidth>
            SATIN AL
          </RV.Button>
        )}

        {state.mode === 'CHECKOUT' && <ProductCheckout contracts={contracts} rnxPropertyId={rnxPropertyId} state={state} actions={actions} />}

        {state.mode === 'PAYMENT' && <ProductPayment state={state} actions={actions} />}
      </Box>
    </Flex>
  )
}

const ProductCheckout = ({
  rnxPropertyId,
  state,
  actions,
  contracts
}) => {
  return (
    <>
      <RV.RfActionForm
        onSuccess={(data) => {
          actions.moveToPayment({ id: data.id })
        }}
        actionKey='PrBase.PrepareAnonymousProductPayment'
        displayAs='FORM'
        fields={[
          {
            name: 'name',
            label: 'İsim Soyisim',
            kind: 'text',
            required: true
          },

          {
            name: 'govId',
            label: 'TC Kimlik No',
            kind: 'text',
            required: true
          },

          {
            name: 'birthDate',
            label: 'Doğum Tarihi',
            kind: 'text',
            description: 'gg.aa.yyyy',
            required: true
          },

          {
            name: 'phone',
            label: 'Telefon',
            kind: 'text',
            required: true
          },
          ...contracts
        ]}
        initialValues={{
          id: rnxPropertyId,
          products: state.selectedProductIds.map(id => ({
            id: id,
            count: state.selectedProducts[id].count
          })),
          kind: 'COMMERCE'
        }}
        buttonText='ÖDEME AŞAMASINA GEÇ' />
    </>
  )
}

import * as cardHelpers from '../cardHelpers'

const CreditCardForm = ({
  operationId
}) => {
  const [nextStep, setNextStep] = React.useState()
  const [formProps, isSubmitting] = useReactorActionForm(
    'PrBase.MakeCreditCardPayment',
    {
      initialValues: {
        id: operationId,
      },
      onSuccess: (data) => {
        setNextStep(data.nextStep)
      }
    }
  )

  if (nextStep) {
    if (nextStep.name === 'CONFIRM_3D_FORM_POST') return <Payment3DPostForm paymentStep={nextStep} />
  }

  return (
    <Box>
      <Form {...formProps}>
        <RfFormField label='Kart Sahibi' name='cc.cardHolderName' />
        <RfFormField name='cc.number' kind='text' format={cardHelpers.formatCreditCardNumber} placeholder='1234 1234 1234 1234' sx={{
          m: 0
        }} />
        <RfFormRow gap={0} sx={{
          mb: 4,
        }}>
          <RfFormField name='cc.validUntil' kind='text' format={cardHelpers.formatExpirationDate} placeholder='AA / YY' sx={{
            m: 0
          }} />
          <RfFormField name='cc.securityCode' kind='text' format={cardHelpers.formatCVC} label='CVC' sx={{
            m: 0
          }} />
        </RfFormRow>
        <RfFormError error={formProps.error} />
        <RV.Button isFullWidth colorScheme='brand' submit my={4} isLoading={isSubmitting}>
          ÖDEME YAP
        </RV.Button>
      </Form>
    </Box>
  )
}

const Payment3DPostForm = ({
  paymentStep
}) => {
  const formRef = React.useRef()
  React.useEffect(() => {
    if (!formRef.current) return null
    formRef.current.submit()
  }, [formRef.current])
  // console.log(paymentStep)
  // return null

  return (
    <Box>
      <RV.Alert status='info' mt={6} sx={{
        textTransform: 'initial',
      }}>
        3D Doğrulama sayfasına yönlendiriliyorsunuz.
      </RV.Alert>
      <form ref={formRef} action={paymentStep.step_data.url} method='POST'>
        {Object.entries(paymentStep.step_data.formData).map(([k, v]) => {
          return (
            <input type='hidden' name={k} value={v} key={k} />
          )
        })}
        {/* <button type='submit'>submit</button> */}
      </form>
    </Box>
  )
}

const ProductPayment = ({
  state
}) => {
  return (
    <CreditCardForm operationId={state.id} />
  )
}

const PrPublicProgram = ({
  property
}) => {
  const params = RV.useParams()
  const result = RV.useReactorQuery({
    'prPublicMembershipProgram': {
      fields: [
        'name', 'description', 'membershipTypes'
      ],
      id: params.rnxMembershipProgramId
    }
  })

  return (
    <Box sx={{
      // width: ['95%', null, null, '50%'],
      // mx: 'auto',
      // my: 8
    }}>
      <RV.Routes>
        <RV.Route path='/' element={(
          <RV.QueryLoading result={result}>
            {({ prPublicMembershipProgram }) => {
              return (
                <Box>
                  <RV.Card my={2}>
                    <RV.Card.Header>
                      {prPublicMembershipProgram.name}
                    </RV.Card.Header>

                    {prPublicMembershipProgram.description && (
                      <RichTextView my={2} value={prPublicMembershipProgram.description} />
                    )}
                  </RV.Card>

                  <RV.Card.Header>
                    Üyelik Türleri
                  </RV.Card.Header>

                  <RV.Alert status='info' my={2}>
                    Seçim yaparak devam edebilirsiniz.
                  </RV.Alert>

                  <Flex flexWrap={'wrap'} mx={-4}>
                    {prPublicMembershipProgram.membershipTypes?.map((t, dx) => {
                      return (
                        <RV.Link key={dx} to={`./register/${t.id}`} sx={{
                          flex: '1 1 0',
                          maxWidth: '25%',
                          textAlign: 'center',
                          minHeight: '150px',
                          mx: 4,
                          display: 'block'
                        }}>
                          <RV.Card >
                            {t.name}
                          </RV.Card>
                        </RV.Link>
                      )
                    })}
                  </Flex>
                </Box>
              )
            }}
          </RV.QueryLoading>
        )} />
        <RV.Route path='register/:rnxMembershipTypeId' element={<MembershipTypeRegister />} />
      </RV.Routes>
    </Box>
  )
}

import DynamicCalendar from 'elements/rnx_activity_group/rnxActivityGroup/components/DynamicCalendar'
// Vendor
import dayjs from 'dayjs'

const ProgramDynamicCalendar = ({
  calendar
}) => {
  if (!calendar) return null
  const activities = React.useMemo(() => {
    const resp = {}
    calendar.activities?.forEach(a => {
      const startHourSplit = a.timeStart.split(':')
      const finishHourSplit = a.timeFinish.split(':')

      const today = dayjs().startOf('today')
      const start = today.hour(startHourSplit[0]).minute(startHourSplit[1])
      const finish = today.hour(finishHourSplit[0]).minute(finishHourSplit[1])

      if (!resp[a.dayOfWeek.key - 1]) resp[a.dayOfWeek.key - 1] = []
      resp[a.dayOfWeek.key - 1].push({
        start, finish, activity: a, name: a.name
      })

    })
    return resp
  }, [calendar.activities])
  return (
    <Box sx={{
      overflow: 'scroll'
    }}>
      <DynamicCalendar activities={activities} />
    </Box>
  )
}

const MembershipTypeRegister = ({

}) => {
  const params = RV.useParams()
  const result = RV.useReactorQuery({
    'prPublicMembershipType': {
      fields: [
        'name', 'description', 'packages', 'model', 'groupInfo'
      ],
      id: params.rnxMembershipTypeId
    }
  })

  return (
    <RV.QueryLoading result={result}>
      {({ prPublicMembershipType }) => {
        return (
          <Box>
            <RV.Text.Header>
              {prPublicMembershipType.name}
            </RV.Text.Header>

            {prPublicMembershipType.description && (
              <RichTextView my={2} value={prPublicMembershipType.description} />
            )}

            <RV.Card>
              <RV.Card.Header>
                Ücretler
              </RV.Card.Header>
              {prPublicMembershipType.packages?.map((pkg, dx) => {
                return (
                  <RV.Layout.Row key={dx}>
                    {pkg.name}
                    <Box>
                      ₺{pkg.price}
                    </Box>
                  </RV.Layout.Row>
                )
              })}
            </RV.Card>

            <RegisterPageWithId id={params.rnxMembershipTypeId} />
          </Box>
        )
      }}
    </RV.QueryLoading>
  )
}

import { useSelector, useDispatch } from 'react-redux'
import reactorActions from 'reactor/systems/reactor/actions'

const Logout = ({

}) => {
  const dispatchAction = RV.useReactorAction('RcApp.LogoutRcUser')
  const navigate = RV.useNavigate()
  const dispatch = useDispatch()

  return (
    <Box as='a' sx={{
      color: 'brand.500',
      textDecoration: 'underline',
      mx: 3
    }} onClick={() => {
      dispatchAction(null, {
        success: () => {
          dispatch(reactorActions.reset())
          navigate('../')
          window.location.reload()
        }
      })
    }}>Çıkış</Box>
  )
}

export default PrPublicSite